var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "authentication-list" },
    [_c("app-list", { attrs: { opt: _vm.opt }, on: { get: _vm.onGet } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }