<template>
  <div class="authentication-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "认证记录",
        search: [
          {
            key: "authRecordDate",
            label: "认证日期",
            type: "date",
            timeType: "yyyy-MM-dd"
          },
          {
            key: "name",
            label: "姓名",
            maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
          },
        //   {
        //     key: "idCardNo",
        //     label: "身份证号",
        //     width: 100
        //   }
          // {
          //   key: "phoneNum",
          //   label: "电话",
          //   type: "number"
          // }
        ],
        columns: [
          {
            label: "姓名",
            key: "name",
            on: row => {
              _this.$router.push(
                "/main/authentication/detail/show/" + row.recordId
              );
            }
          },
          { label: "用户类型", key: "typeStr" },
          { label: "性别", key: "gender", width: 200 },
          { label: "身份证号码", key: "idNo", width: 200 },
          { label: "认证时间", key: "authTime" },
          { label: "认证结果", key: "anthRetStr" }
        ]
      }
    };
  },
  created() {
    console.log("sale-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        ...opt.searchForm
      };
      this.post("device-service/rzjmanagment/selectAuthRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          // item.apartmentIdStr = apartmentList[item.apartmentId];
          item.idNo =item.idNo?
            item.idNo.substring(0, 2) +
            "******" +
            item.idNo.substring(item.idNo.length - 2, item.idNo.length):'';
          item.dateStr = this.format(item.date);
          item.anthRetStr = ["成功", "失败"][Number(item.authRst) - 1];
          item.typeStr = [
            "系统管理员",
            "房东",
            "工程人员",
            "财务",
            "房客",
            "房管员",
            "",
            "",
            "",
            "未知人员"
          ][item.userType - 1];
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>